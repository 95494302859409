<template>
  <app-form-view
    app="lawsuit"
    model="lawsuit"
    api-url="lawsuit/lawsuit/"
    :title="$t('menu.lawsuit')"
    :state="formData.state"
    :stateItems="stateItems"
    :approveItems="approveItems"
    v-model="formData"
    :prepare-data="prepareData"
  >
    <template v-slot="{ view, mode }">
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="name"
            rules="required"
            :label="$t('fields.lawsuitName')"
            :view="view"
            v-model="formData.name"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_name"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="company"
            type="select-server"
            rules="required"
            :label="$t('fields.companyName')"
            :view="view"
            :binds="{
              apiUrl: 'contact/contact/?contact_type=company'
            }"
            v-model="formData.company"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveCompany"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_company"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="lawsuit_category"
            type="select-server"
            :label="$t('fields.lawsuitCategory')"
            :view="view"
            :binds="{
              apiUrl: 'lawsuit/lawsuit-category/?state=approved&active=true'
            }"
            v-model="formData.lawsuit_category"
            @input="getLawsuitType"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveLiabilityCategory"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_lawsuit_category"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="company_branch"
            type="select-server"
            :label="$t('fields.branch')"
            :view="view"
            :binds="{
              apiUrl: formData.company
                ? `contact/contact-branch/?contact_id=${formData.company.id}&state=approved`
                : ''
            }"
            v-model="formData.company_branch"
            :disabled="!formData.company"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveCompanyBranch"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_company_branch"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="lawsuit_type"
            type="select-server"
            :label="$t('fields.lawsuitType')"
            :view="view"
            :binds="{
              apiUrl: lawsuitTypeUrl
            }"
            v-model="formData.lawsuit_type"
            :disabled="!Boolean(formData.lawsuit_category)"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveLiabilityType"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_lawsuit_type"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="date"
            type="datepicker"
            :label="$t('fields.date')"
            :view="view"
            v-model="formData.date"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveDate"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_date"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="lawsuit_group"
            type="select-server"
            :label="$t('fields.lawsuitGroup')"
            :view="view"
            :disabled="!formData || !formData.company"
            :binds="{
              apiUrl:
                formData && formData.company
                  ? `lawsuit/lawsuit-group/?company=${formData.company.id}&state=approved&active=true`
                  : ''
            }"
            v-model="formData.lawsuit_group"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveLiabilityGroup"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_lawsuit_group"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="business_unit"
            type="select-server"
            :label="$t('fields.businessUnit')"
            :view="view"
            :binds="{
              apiUrl: formData.company
                ? `contact/contact-business-unit/?contact_id=${formData.company.id}&state=approved`
                : '',
              itemText: 'business_unit_id.name'
            }"
            name-accessor="business_unit_id.name"
            v-model="formData.company_business_unit"
            :disabled="!formData.company"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveBusinessUnit"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_business_unit"
          />
        </v-col>
        <!--        <v-col cols="12" lg="6">-->
        <!--          <app-input-->
        <!--            name="collateral"-->
        <!--            type="switch"-->
        <!--            :label="$t('fields.collateralAsset')"-->
        <!--            :view="view"-->
        <!--            v-model="formData.collateral"-->
        <!--          />-->
        <!--        </v-col>-->
      </v-row>

      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="related_to"
            type="chips-server"
            :label="$t('fields.relatedTo')"
            :view="view"
            :binds="{
              apiUrl: `asset/related-to/`,
              chip: {
                color: 'primary',
                value: 'name'
              }
            }"
            v-model="formData.related_to"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveRelatedTo"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_related_to"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="validity_status"
            type="switch"
            :label="$t('fields.validityStatus')"
            :view="view"
            v-model="formData.validity_status"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveValidityStatus"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_validity_status"
          />
        </v-col>
      </v-row>

      <!--      <v-row>-->
      <!--        <v-col cols="12" lg="6">-->
      <!--          <app-input-->
      <!--            name="using_status"-->
      <!--            type="switch"-->
      <!--            :label="$t('fields.usingStatus')"-->
      <!--            :view="view"-->
      <!--            v-model="formData.using_status"-->
      <!--          />-->
      <!--        </v-col>-->
      <!--        <v-col cols="12" lg="6">-->
      <!--          <app-input-->
      <!--            name="business_unit"-->
      <!--            type="select-server"-->
      <!--            :label="$t('fields.businessUnit')"-->
      <!--            :view="view"-->
      <!--            :binds="{-->
      <!--              apiUrl: formData.company-->
      <!--                ? `contact/contact-business-unit/?contact_id=${formData.company.id}&state=approved`-->
      <!--                : '',-->
      <!--              itemText: 'business_unit_id.name'-->
      <!--            }"-->
      <!--            v-model="formData.company_business_unit"-->
      <!--            :disabled="!formData.company"-->
      <!--          />-->
      <!--        </v-col>-->
      <!--      </v-row>-->

      <template
        v-if="formData.display_fields && formData.display_fields.length"
      >
        <v-row
          v-for="displayField in formData.display_fields"
          :key="displayField.id"
        >
          <v-col
            v-for="field in displayField.fields"
            :key="`${displayField.id}-${field.field.field}`"
            cols="12"
            lg="6"
          >
            <app-input
              :name="`${displayField.id}-${field.field.field}`"
              type="text"
              :label="field.field.name"
              view
              :value="field.field_value"
            />
          </v-col>
        </v-row>
      </template>

      <v-card>
        <v-tabs dark background-color="primary" v-model="tab">
          <v-tab>{{ $t('menu.description') }}</v-tab>
          <v-tab v-if="mode !== 'create'">{{ $t('menu.relatedParty') }}</v-tab>
          <v-tab v-if="mode !== 'create'"
            >{{ $t('menu.requiredDocument') }}
          </v-tab>
          <v-tab v-if="mode !== 'create' && isRelatedToIdSelected('as')"
            >{{ $t('menu.relatedAsset') }}
          </v-tab>
          <v-tab v-if="mode !== 'create' && isRelatedToIdSelected('eq')"
            >{{ $t('menu.relatedEquity') }}
          </v-tab>
          <v-tab v-if="mode !== 'create' && isRelatedToIdSelected('la')"
            >{{ $t('menu.relatedLiability') }}
          </v-tab>
          <v-tab v-if="mode !== 'create' && isRelatedToIdSelected('hu')"
            >{{ $t('menu.relatedHumanAsset') }}
          </v-tab>
          <v-tab v-if="mode !== 'create' && isRelatedToIdSelected('lw')"
            >{{ $t('menu.relatedLawsuit') }}
          </v-tab>
          <v-tab v-if="mode !== 'create' && isRelatedToIdSelected('re')"
            >{{ $t('menu.relatedRevenue') }}
          </v-tab>
          <v-tab v-if="mode !== 'create' && isRelatedToIdSelected('ex')"
            >{{ $t('menu.relatedExpense') }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <app-input
                  name="Description"
                  type="html-editor"
                  :label="$t('fields.description')"
                  :view="view"
                  v-model="formData.description"
                />
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="mode !== 'create'">
            <app-table
              server-side
              ref="related_parties_table"
              app="lawsuit"
              model="lawsuit"
              :hide-edit="mode !== 'edit'"
              :hide-delete="mode !== 'edit'"
              :headers="relatedPartyHeaders"
              :server-items="relatedParties"
              :server-items-length="relatedPartiesCount"
              :is-selecteable="true"
              v-model="selectedRelatedParties"
              :loading="loading"
              @server="getRelatedParties"
              @edit="onEditRelatedParty"
              @delete="onDeleteRelatedParty"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Related Party</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-dialog v-model="dialogRelatedParty" max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                      <slot name="actions">
                        <v-btn
                          v-show="mode === 'edit'"
                          dark
                          color="primary"
                          v-bind="attrs"
                          v-on="on"
                        >
                          New Item
                        </v-btn>
                        <v-btn
                          v-show="
                            selectedRelatedParties.length && mode === 'edit'
                          "
                          color="error"
                          @click="onDeleteRelatedParty(selectedRelatedParties)"
                        >
                          {{ $t('btn.delete') }}
                        </v-btn>
                      </slot>
                    </template>
                    <v-card>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" class="app-input--approve-wrapper">
                              <app-input
                                v-model="editingRelatedParty.party"
                                name="party"
                                type="select-server"
                                :label="$t('fields.name')"
                                :view="view"
                                :binds="{
                                  apiUrl:
                                    'contact/contact/?contact_type=company'
                                }"
                              />
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" class="app-input--approve-wrapper">
                              <app-input
                                v-model="editingRelatedParty.note"
                                name="note"
                                :label="$t('fields.note')"
                                :view="view"
                              ></app-input>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="onCloseRelatedParty"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="onSaveRelatedParty"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
            </app-table>
          </v-tab-item>
          <v-tab-item v-if="mode !== 'create'">
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  app="lawsuit"
                  model="lawsuitrequireddocument"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :headers="requiredDocHeader"
                  :server-items="requiredDocs"
                  :server-items-length="requiredDocsCount"
                  :loading="loading"
                  @server="getRequiredDocs"
                  @delete="onDeleteRequiredDoc"
                  @edit="onEditRequiredDoc"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title>Required Document</v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>

                          <v-dialog
                            v-model="dialogRequiredDoc"
                            max-width="500px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <slot name="actions">
                                <slot name="prefix-actions" />
                                <v-btn
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  v-show="mode === 'edit'"
                                >
                                  New Item
                                </v-btn>

                                <slot name="suffix-actions" />
                              </slot>
                            </template>
                            <v-card>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col
                                      cols="6"
                                      class="app-input--approve-wrapper"
                                    >
                                      <app-input
                                        v-model="
                                          editingRequiredDoc.lawsuit_type_required_document
                                        "
                                        name="lawsuit_type_required_document"
                                        type="select-server"
                                        :label="$t('fields.requiredDocument')"
                                        :view="view"
                                        :binds="{
                                          apiUrl: `lawsuit/lawsuit-type-required-document/?lawsuit_type=${formData.lawsuit_type.id}`,
                                          itemText: 'document_template.name'
                                        }"
                                      >
                                      </app-input>
                                    </v-col>
                                    <v-col
                                      cols="6"
                                      class="app-input--approve-wrapper"
                                    >
                                      <app-input
                                        v-model="
                                          editingRequiredDoc.personal_document
                                        "
                                        name="personal_document"
                                        type="select-server"
                                        :label="
                                          $t('label.selectRequiredDocument')
                                        "
                                        :view="view"
                                        :binds="{
                                          apiUrl:
                                            editingRequiredDoc.lawsuit_type_required_document &&
                                            formData.company
                                              ? `contact/personal-doc/?document_template_id=${editingRequiredDoc.lawsuit_type_required_document.document_template.id}&contact_ids=${formData.company.id}&state=approved&active=true`
                                              : '',
                                          itemText: 'document_name'
                                        }"
                                      >
                                      </app-input>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="onCloseRequiredDoc"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="onSaveRequiredDoc"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="mode !== 'create' && isRelatedToIdSelected('as')">
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  app="lawsuit"
                  model="lawsuit"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :headers="relatedToHeader('as')"
                  :server-items="relatedAssets"
                  :server-items-length="relatedAssetsCount"
                  :loading="loading"
                  @server="getRelatedAsset"
                  @delete="onDeleteRelatedAsset"
                  @edit="onEditRelatedAsset"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title
                            >{{ $t('menu.relatedAsset') }}
                          </v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>

                          <v-dialog
                            v-model="dialogRelatedAsset"
                            max-width="500px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <slot name="actions">
                                <slot name="prefix-actions" />
                                <v-btn
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  v-show="mode === 'edit'"
                                >
                                  New Item
                                </v-btn>

                                <slot name="suffix-actions" />
                              </slot>
                            </template>
                            <v-card>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      class="app-input--approve-wrapper"
                                    >
                                      <app-input
                                        v-model="editingRelatedAsset.asset"
                                        name="asset"
                                        type="select-server"
                                        :label="$t('fields.asset')"
                                        :view="view"
                                        :disabled="
                                          !formData || !formData.company
                                        "
                                        :binds="{
                                          apiUrl:
                                            formData && formData.company
                                              ? `asset/asset/?company=${formData.company.id}&state=approved`
                                              : '',
                                          itemText: 'name'
                                        }"
                                      >
                                      </app-input>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="onCloseRelatedAsset"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="onSaveRelatedAsset"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="mode !== 'create' && isRelatedToIdSelected('eq')">
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  app="lawsuit"
                  model="lawsuit"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :headers="relatedToHeader('eq')"
                  :server-items="relatedEquities"
                  :server-items-length="relatedEquitiesCount"
                  :loading="loading"
                  @server="getRelatedEquity"
                  @delete="onDeleteRelatedEquity"
                  @edit="onEditRelatedEquity"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title
                            >{{ $t('menu.relatedEquity') }}
                          </v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>

                          <v-dialog
                            v-model="dialogRelatedEquity"
                            max-width="500px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <slot name="actions">
                                <slot name="prefix-actions" />
                                <v-btn
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  v-show="mode === 'edit'"
                                >
                                  New Item
                                </v-btn>

                                <slot name="suffix-actions" />
                              </slot>
                            </template>
                            <v-card>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      class="app-input--approve-wrapper"
                                    >
                                      <app-input
                                        v-model="editingRelatedEquity.equity"
                                        name="equity"
                                        type="select-server"
                                        :label="$t('fields.equity')"
                                        :view="view"
                                        :disabled="
                                          !formData || !formData.company
                                        "
                                        :binds="{
                                          apiUrl:
                                            formData && formData.company
                                              ? `equity/equity/?company=${formData.company.id}&state=approved`
                                              : '',
                                          itemText: 'name'
                                        }"
                                      >
                                      </app-input>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="onCloseRelatedEquity"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="onSaveRelatedEquity"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="mode !== 'create' && isRelatedToIdSelected('la')">
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  app="lawsuit"
                  model="lawsuit"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :headers="relatedToHeader('la')"
                  :server-items="relatedLiabilities"
                  :server-items-length="relatedLiabilitiesCount"
                  :loading="loading"
                  @server="getRelatedLiability"
                  @delete="onDeleteRelatedLiability"
                  @edit="onEditRelatedLiability"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title
                            >{{ $t('menu.relatedLiability') }}
                          </v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>

                          <v-dialog
                            v-model="dialogRelatedLiability"
                            max-width="500px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <slot name="actions">
                                <slot name="prefix-actions" />
                                <v-btn
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  v-show="mode === 'edit'"
                                >
                                  New Item
                                </v-btn>

                                <slot name="suffix-actions" />
                              </slot>
                            </template>
                            <v-card>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      class="app-input--approve-wrapper"
                                    >
                                      <app-input
                                        v-model="
                                          editingRelatedLiability.liability
                                        "
                                        name="liability"
                                        type="select-server"
                                        :label="$t('fields.liability')"
                                        :view="view"
                                        :disabled="
                                          !formData || !formData.company
                                        "
                                        :binds="{
                                          apiUrl:
                                            formData && formData.company
                                              ? `liability/liability/?company=${formData.company.id}&state=approved`
                                              : '',
                                          itemText: 'name'
                                        }"
                                      >
                                      </app-input>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="onCloseRelatedLiability"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="onSaveRelatedLiability"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="mode !== 'create' && isRelatedToIdSelected('hu')">
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  app="lawsuit"
                  model="lawsuit"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :headers="relatedToHeader('hu')"
                  :server-items="relatedHumanAssets"
                  :server-items-length="relatedHumanAssetsCount"
                  :loading="loading"
                  @server="getRelatedHumanAsset"
                  @delete="onDeleteRelatedHumanAsset"
                  @edit="onEditRelatedHumanAsset"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title
                            >{{ $t('menu.relatedHumanAsset') }}
                          </v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>

                          <v-dialog
                            v-model="dialogRelatedHumanAsset"
                            max-width="500px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <slot name="actions">
                                <slot name="prefix-actions" />
                                <v-btn
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  v-show="mode === 'edit'"
                                >
                                  New Item
                                </v-btn>

                                <slot name="suffix-actions" />
                              </slot>
                            </template>
                            <v-card>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      class="app-input--approve-wrapper"
                                    >
                                      <app-input
                                        v-model="
                                          editingRelatedHumanAsset.human_asset
                                        "
                                        name="human_asset"
                                        type="select-server"
                                        :label="$t('fields.humanAsset')"
                                        :view="view"
                                        :disabled="
                                          !formData || !formData.company
                                        "
                                        :binds="{
                                          apiUrl:
                                            formData && formData.company
                                              ? `human-asset/human-asset/?company=${formData.company.id}&state=approved`
                                              : '',
                                          itemText: 'name'
                                        }"
                                      >
                                      </app-input>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="onCloseRelatedHumanAsset"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="onSaveRelatedHumanAsset"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="mode !== 'create' && isRelatedToIdSelected('lw')">
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  app="lawsuit"
                  model="lawsuit"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :headers="relatedToHeader('lw')"
                  :server-items="relatedLawsuits"
                  :server-items-length="relatedLawsuitsCount"
                  :loading="loading"
                  @server="getRelatedLawsuit"
                  @delete="onDeleteRelatedLawsuit"
                  @edit="onEditRelatedLawsuit"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title
                            >{{ $t('menu.relatedLawsuit') }}
                          </v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>

                          <v-dialog
                            v-model="dialogRelatedLawsuit"
                            max-width="500px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <slot name="actions">
                                <slot name="prefix-actions" />
                                <v-btn
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  v-show="mode === 'edit'"
                                >
                                  New Item
                                </v-btn>

                                <slot name="suffix-actions" />
                              </slot>
                            </template>
                            <v-card>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      class="app-input--approve-wrapper"
                                    >
                                      <app-input
                                        v-model="editingRelatedLawsuit.lawsuit"
                                        name="lawsuit"
                                        type="select-server"
                                        :label="$t('fields.lawsuit')"
                                        :view="view"
                                        :disabled="
                                          !formData || !formData.company
                                        "
                                        :binds="{
                                          apiUrl:
                                            formData && formData.company
                                              ? `lawsuit/lawsuit/?company=${formData.company.id}&state=approved`
                                              : '',
                                          itemText: 'name'
                                        }"
                                      >
                                      </app-input>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="onCloseRelatedLawsuit"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="onSaveRelatedLawsuit"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="mode !== 'create' && isRelatedToIdSelected('re')">
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  app="lawsuit"
                  model="lawsuit"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :headers="relatedToHeader('re')"
                  :server-items="relatedRevenues"
                  :server-items-length="relatedRevenuesCount"
                  :loading="loading"
                  @server="getRelatedRevenue"
                  @delete="onDeleteRelatedRevenue"
                  @edit="onEditRelatedRevenue"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title
                            >{{ $t('menu.relatedRevenue') }}
                          </v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>

                          <v-dialog
                            v-model="dialogRelatedRevenue"
                            max-width="500px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <slot name="actions">
                                <slot name="prefix-actions" />
                                <v-btn
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  v-show="mode === 'edit'"
                                >
                                  New Item
                                </v-btn>

                                <slot name="suffix-actions" />
                              </slot>
                            </template>
                            <v-card>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      class="app-input--approve-wrapper"
                                    >
                                      <app-input
                                        v-model="editingRelatedRevenue.revenue"
                                        name="revenue"
                                        type="select-server"
                                        :label="$t('fields.revenue')"
                                        :view="view"
                                        :disabled="
                                          !formData || !formData.company
                                        "
                                        :binds="{
                                          apiUrl:
                                            formData && formData.company
                                              ? `revenue/revenue/?company=${formData.company.id}&state=approved`
                                              : '',
                                          itemText: 'name'
                                        }"
                                      >
                                      </app-input>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="onCloseRelatedRevenue"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="onSaveRelatedRevenue"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="mode !== 'create' && isRelatedToIdSelected('ex')">
            <v-card flat>
              <v-card-text>
                <app-table
                  server-side
                  app="lawsuit"
                  model="lawsuit"
                  :hide-edit="mode !== 'edit'"
                  :hide-delete="mode !== 'edit'"
                  :headers="relatedToHeader('ex')"
                  :server-items="relatedExpenses"
                  :server-items-length="relatedExpensesCount"
                  :loading="loading"
                  @server="getRelatedExpense"
                  @delete="onDeleteRelatedExpense"
                  @edit="onEditRelatedExpense"
                >
                  <template v-slot:top>
                    <div class="app-list-view">
                      <div class="app-list-view--header">
                        <v-toolbar flat>
                          <v-toolbar-title
                            >{{ $t('menu.relatedExpense') }}
                          </v-toolbar-title>
                          <v-divider class="mx-4" inset vertical></v-divider>
                          <v-spacer></v-spacer>

                          <v-dialog
                            v-model="dialogRelatedExpense"
                            max-width="500px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <slot name="actions">
                                <slot name="prefix-actions" />
                                <v-btn
                                  color="primary"
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                  v-show="mode === 'edit'"
                                >
                                  New Item
                                </v-btn>

                                <slot name="suffix-actions" />
                              </slot>
                            </template>
                            <v-card>
                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      class="app-input--approve-wrapper"
                                    >
                                      <app-input
                                        v-model="editingRelatedExpense.expense"
                                        name="expense"
                                        type="select-server"
                                        :label="$t('fields.expense')"
                                        :view="view"
                                        :disabled="
                                          !formData || !formData.company
                                        "
                                        :binds="{
                                          apiUrl:
                                            formData && formData.company
                                              ? `expense/expense/?company=${formData.company.id}&state=approved`
                                              : '',
                                          itemText: 'name'
                                        }"
                                      >
                                      </app-input>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="onCloseRelatedExpense"
                                >
                                  Cancel
                                </v-btn>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="onSaveRelatedExpense"
                                >
                                  Save
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </v-toolbar>
                      </div>
                    </div>
                  </template>
                </app-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </template>
  </app-form-view>
</template>

<script>
import AppFormView from '@components/AppFormView'
import AppInput from '@components/AppInput.vue'
import { defaultTableParams } from '@utils/app-table'
import AppTable from '@components/AppTable.vue'
import relatedToItems from '@composables/asset/relatedToItems'

export default {
  name: 'lawsuit-form',
  components: {
    AppTable,
    AppInput,
    AppFormView
  },
  data() {
    return {
      formData: {
        state: 'draft'
      },
      approveItems: [
        'approve_name',
        'approve_company',
        'approve_lawsuit_category',
        'approve_company_branch',
        'approve_lawsuit_type',
        'approve_date',
        'approve_lawsuit_group',
        'approve_business_unit',
        'approve_related_to',
        'approve_validity_status'
      ],
      loading: false,
      tab: null,
      relatedParties: [],
      relatedPartiesCount: 0,
      selectedRelatedParties: [],
      editingRelatedParty: {},
      dialogRelatedParty: false,
      requiredDocs: [],
      requiredDocsCount: 0,
      dialogRequiredDoc: false,
      editingRequiredDoc: {},
      dialogRelatedAsset: false,
      relatedAssets: [],
      relatedAssetsCount: 0,
      editingRelatedAsset: {},
      dialogRelatedLiability: false,
      relatedLiabilities: [],
      relatedLiabilitiesCount: 0,
      editingRelatedLiability: {},
      dialogRelatedEquity: false,
      relatedEquities: [],
      relatedEquitiesCount: 0,
      editingRelatedEquity: {},
      dialogRelatedRevenue: false,
      relatedRevenues: [],
      relatedRevenuesCount: 0,
      editingRelatedRevenue: {},
      dialogRelatedExpense: false,
      relatedExpenses: [],
      relatedExpensesCount: 0,
      editingRelatedExpense: {},
      dialogRelatedHumanAsset: false,
      relatedHumanAssets: [],
      relatedHumanAssetsCount: 0,
      editingRelatedHumanAsset: {},
      dialogRelatedLawsuit: false,
      relatedLawsuits: [],
      relatedLawsuitsCount: 0,
      editingRelatedLawsuit: {},
      lawsuitTypeUrl: null
    }
  },
  computed: {
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    },
    relatedToItems: relatedToItems,
    lawsuitId() {
      return this.$route.params.id
    },
    relatedPartyHeaders() {
      return [
        {
          text: this.$t('fields.name'),
          value: 'name',
          hideFilter: true
        },
        {
          text: this.$t('fields.note'),
          value: 'note',
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    requiredDocHeader() {
      return [
        {
          text: this.$t('fields.requiredDocument'),
          value: 'lawsuit_type_required_document.document_template.name',
          hideFilter: true
        },
        {
          text: this.$t('fields.name'),
          value: 'personal_document.document_name',
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    }
  },
  created() {
    if (this.$route.name === 'lawsuitCreate') {
      this.$store.dispatch('addBreadcrumb', {
        name: 'lawsuitCreate'
      })
    }
  },
  watch: {
    'formData.name': function (value) {
      if (this.$route.name === 'lawsuitEdit') {
        this.$store.dispatch('addBreadcrumb', {
          name: 'lawsuitEdit',
          text: value ?? 'N/A',
          to: {
            name: 'lawsuitEdit',
            params: { id: this.$route.params.id }
          }
        })
      }
    }
  },
  methods: {
    prepareData() {
      const data = { ...this.formData }
      data.company = data.company?.id ?? ''
      data.company_branch = data.company_branch?.id ?? ''
      data.company_business_unit = data.company_business_unit?.id ?? ''
      data.lawsuit_category = data.lawsuit_category?.id ?? ''
      data.lawsuit_type = data.lawsuit_type?.id ?? ''
      data.lawsuit_group = data.lawsuit_group?.id ?? ''
      // data.date = data.date ? this.$moment(data.date).format('YYYY-MM-DD') : ''
      data.related_to = data.related_to?.map((item) => item.id) ?? []
      return data
    },
    async reloadData() {
      const { data } = await this.$api({
        method: 'get',
        url: `lawsuit/lawsuit/${this.lawsuitId}`,
        hideSuccessAlert: true
      })
      this.formData = data.detail
    },
    async getRelatedParties(options = null) {
      if (!this.lawsuitId) {
        return
      }
      this.loading = true
      try {
        let params = defaultTableParams(options)
        params.lawsuit = this.lawsuitId

        const { data } = await this.$api({
          method: 'get',
          url: `lawsuit/lawsuit/${this.lawsuitId}/related-party/`,
          params: params,
          hideSuccessAlert: true
        })
        this.relatedParties = data.results
        this.relatedPartiesCount = data.count
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditRelatedParty(item) {
      this.editingRelatedParty = Object.assign(
        {},
        {
          note: item.note,
          party: item,
          id: item.id
        }
      )
      this.dialogRelatedParty = true
    },
    onDeleteRelatedParty(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `lawsuit/lawsuit/${this.lawsuitId}/related-party/`,
          data: {
            pks: items.map((value) => value.id)
          }
        })
          .then(() => {
            this.selectedRelatedParties = []
            this.$refs.related_parties_table.onServer()
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    onCloseRelatedParty() {
      this.dialogRelatedParty = false
      this.editingRelatedParty = {}
    },
    async onSaveRelatedParty() {
      this.loading = true
      try {
        const party_id = this.editingRelatedParty.party?.id
          ? this.editingRelatedParty.party.id
          : this.editingRelatedParty.party
        const data = {
          party: party_id,
          note: this.editingRelatedParty.note
        }
        if (this.editingRelatedParty.id) {
          await this.$api({
            method: 'put',
            url: `lawsuit/lawsuit/${this.lawsuitId}/related-party/${party_id}/`,
            data: data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `lawsuit/lawsuit/${this.lawsuitId}/related-party/`,
            data: data
          })
        }

        this.loading = false
        this.onCloseRelatedParty()
        this.$refs.related_parties_table.onServer()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getRequiredDocs(options = null) {
      if (!this.lawsuitId) {
        return
      }

      this.loading = true
      try {
        let params = defaultTableParams(options)
        params.lawsuit = this.lawsuitId

        const { data } = await this.$api({
          method: 'get',
          url: `lawsuit/lawsuit-required-document/`,
          params: params,
          hideSuccessAlert: true
        })
        this.requiredDocs = data.results
        this.requiredDocsCount = data.count ?? 0
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditRequiredDoc(item) {
      this.editingRequiredDoc = Object.assign({}, item)
      this.dialogRequiredDoc = true
    },
    onDeleteRequiredDoc(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `lawsuit/lawsuit-required-document/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getRequiredDocs()
          this.reloadData()
        })
      })
      this.loading = false
    },
    onCloseRequiredDoc() {
      this.dialogRequiredDoc = false
      this.$nextTick(() => {
        this.editingRequiredDoc = {}
      })
      this.loading = false
    },
    async onSaveRequiredDoc() {
      if (!this.editingRequiredDoc.lawsuit_type_required_document) {
        return
      }
      try {
        this.loading = true
        const data = {}
        data.lawsuit = this.lawsuitId
        data.lawsuit_type_required_document =
          this.editingRequiredDoc.lawsuit_type_required_document.id
        data.personal_document = this.editingRequiredDoc.personal_document.id

        if (this.editingRequiredDoc.id) {
          await this.$api({
            method: 'put',
            url: `lawsuit/lawsuit-required-document/${this.editingRequiredDoc.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `lawsuit/lawsuit-required-document/`,
            data
          })
        }
        this.editingRequiredDoc = {}
        this.onCloseRequiredDoc()
        await this.getRequiredDocs()
      } catch (e) {
        console.log(e)
      } finally {
        this.reloadData()
        this.loading = false
      }
    },
    findRelatedToFromValue(value) {
      return this.relatedToItems.find((item) => item.value === value)
    },
    relatedToHeader(value) {
      const item = this.findRelatedToFromValue(value)
      return [
        {
          text: item.label,
          value: 'name',
          hideFilter: true
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          hideFilter: true
        }
      ]
    },
    async getRelatedAsset(options = null) {
      if (!this.lawsuitId) {
        return
      }

      this.loading = true
      try {
        let params = defaultTableParams(options)

        const { data } = await this.$api({
          method: 'get',
          url: `lawsuit/lawsuit/${this.lawsuitId}/related-asset/`,
          params: params,
          hideSuccessAlert: true
        })
        this.relatedAssets = data.results
        this.relatedAssetsCount = data.count ?? 0
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditRelatedAsset(item) {
      this.editingRelatedAsset = {
        asset: Object.assign({}, item)
      }
      this.dialogRelatedAsset = true
    },
    onDeleteRelatedAsset(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `lawsuit/lawsuit/${this.lawsuitId}/related-asset/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getRelatedAsset()
        })
      })
      this.loading = false
    },
    onCloseRelatedAsset() {
      this.dialogRelatedAsset = false
      this.$nextTick(() => {
        this.editingRelatedAsset = {}
      })
      this.loading = false
    },
    async onSaveRelatedAsset() {
      if (!this.editingRelatedAsset.asset) {
        return
      }
      try {
        this.loading = true
        const data = {}
        data.asset = this.editingRelatedAsset.asset.id

        if (this.editingRelatedAsset.id) {
          await this.$api({
            method: 'put',
            url: `lawsuit/lawsuit/${this.lawsuitId}/related-asset/${this.editingRelatedAsset.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `lawsuit/lawsuit/${this.lawsuitId}/related-asset/`,
            data
          })
        }
        this.editingRelatedAsset = {}
        this.onCloseRelatedAsset()
        await this.getRelatedAsset()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    isRelatedToIdSelected(id) {
      return (
        this.formData.related_to &&
        this.formData.related_to.findIndex((item) => item.id === id) >= 0
      )
    },
    getLawsuitType() {
      var url = ''
      if (
        this.formData.lawsuit_category !== null &&
        this.formData.lawsuit_category !== undefined
      ) {
        url =
          'lawsuit/lawsuit-type/?state=approved&active=true&asset_categories__id=' +
          this.formData.lawsuit_category.id
      } else {
        url = 'lawsuit/lawsuit-type/?state=approved&active=true'
      }
      this.lawsuitTypeUrl = url
    },
    async getRelatedEquity(options = null) {
      if (!this.lawsuitId) {
        return
      }

      this.loading = true
      try {
        let params = defaultTableParams(options)

        const { data } = await this.$api({
          method: 'get',
          url: `lawsuit/lawsuit/${this.lawsuitId}/related-equity/`,
          params: params,
          hideSuccessAlert: true
        })
        this.relatedEquities = data.results
        this.relatedEquitiesCount = data.count ?? 0
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditRelatedEquity(item) {
      this.editingRelatedEquity = {
        equity: Object.assign({}, item)
      }
      this.dialogRelatedEquity = true
    },
    onDeleteRelatedEquity(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `lawsuit/lawsuit/${this.lawsuitId}/related-equity/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getRelatedEquity()
        })
      })
      this.loading = false
    },
    onCloseRelatedEquity() {
      this.dialogRelatedEquity = false
      this.$nextTick(() => {
        this.editingRelatedEquity = {}
      })
      this.loading = false
    },
    async onSaveRelatedEquity() {
      if (!this.editingRelatedEquity.equity) {
        return
      }
      try {
        this.loading = true
        const data = {}
        data.equity = this.editingRelatedEquity.equity.id

        if (this.editingRelatedEquity.id) {
          await this.$api({
            method: 'put',
            url: `lawsuit/lawsuit/${this.lawsuitId}/related-equity/${this.editingRelatedEquity.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `lawsuit/lawsuit/${this.lawsuitId}/related-equity/`,
            data
          })
        }
        this.editingRelatedEquity = {}
        this.onCloseRelatedEquity()
        await this.getRelatedEquity()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getRelatedLiability(options = null) {
      if (!this.lawsuitId) {
        return
      }

      this.loading = true
      try {
        let params = defaultTableParams(options)

        const { data } = await this.$api({
          method: 'get',
          url: `lawsuit/lawsuit/${this.lawsuitId}/related-liability/`,
          params: params,
          hideSuccessAlert: true
        })
        this.relatedLiabilities = data.results
        this.relatedLiabilitiesCount = data.count ?? 0
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditRelatedLiability(item) {
      this.editingRelatedLiability = {
        liability: Object.assign({}, item)
      }
      this.dialogRelatedLiability = true
    },
    onDeleteRelatedLiability(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `lawsuit/lawsuit/${this.lawsuitId}/related-liability/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getRelatedLiability()
        })
      })
      this.loading = false
    },
    onCloseRelatedLiability() {
      this.dialogRelatedLiability = false
      this.$nextTick(() => {
        this.editingRelatedLiability = {}
      })
      this.loading = false
    },
    async onSaveRelatedLiability() {
      if (!this.editingRelatedLiability.liability) {
        return
      }
      try {
        this.loading = true
        const data = {}
        data.liability = this.editingRelatedLiability.liability.id

        if (this.editingRelatedLiability.id) {
          await this.$api({
            method: 'put',
            url: `lawsuit/lawsuit/${this.lawsuitId}/related-liability/${this.editingRelatedLiability.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `lawsuit/lawsuit/${this.lawsuitId}/related-liability/`,
            data
          })
        }
        this.editingRelatedLiability = {}
        this.onCloseRelatedLiability()
        await this.getRelatedLiability()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getRelatedHumanAsset(options = null) {
      if (!this.lawsuitId) {
        return
      }

      this.loading = true
      try {
        let params = defaultTableParams(options)

        const { data } = await this.$api({
          method: 'get',
          url: `lawsuit/lawsuit/${this.lawsuitId}/related-human-asset/`,
          params: params,
          hideSuccessAlert: true
        })
        this.relatedHumanAssets = data.results
        this.relatedHumanAssetsCount = data.count ?? 0
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditRelatedHumanAsset(item) {
      this.editingRelatedHumanAsset = {
        human_asset: Object.assign({}, item)
      }
      this.dialogRelatedHumanAsset = true
    },
    onDeleteRelatedHumanAsset(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `lawsuit/lawsuit/${this.lawsuitId}/related-human-asset/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getRelatedHumanAsset()
        })
      })
      this.loading = false
    },
    onCloseRelatedHumanAsset() {
      this.dialogRelatedHumanAsset = false
      this.$nextTick(() => {
        this.editingRelatedHumanAsset = {}
      })
      this.loading = false
    },
    async onSaveRelatedHumanAsset() {
      if (!this.editingRelatedHumanAsset.human_asset) {
        return
      }
      try {
        this.loading = true
        const data = {}
        data.human_asset = this.editingRelatedHumanAsset.human_asset.id

        if (this.editingRelatedLiability.id) {
          await this.$api({
            method: 'put',
            url: `lawsuit/lawsuit/${this.lawsuitId}/related-human-asset/${this.editingRelatedHumanAsset.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `lawsuit/lawsuit/${this.lawsuitId}/related-human-asset/`,
            data
          })
        }
        this.editingRelatedHumanAsset = {}
        this.onCloseRelatedHumanAsset()
        await this.getRelatedHumanAsset()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getRelatedLawsuit(options = null) {
      if (!this.lawsuitId) {
        return
      }

      this.loading = true
      try {
        let params = defaultTableParams(options)

        const { data } = await this.$api({
          method: 'get',
          url: `lawsuit/lawsuit/${this.lawsuitId}/related-lawsuit/`,
          params: params,
          hideSuccessAlert: true
        })
        this.relatedLawsuits = data.results
        this.relatedLawsuitsCount = data.count ?? 0
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditRelatedLawsuit(item) {
      this.editingRelatedLawsuit = {
        lawsuit: Object.assign({}, item)
      }
      this.dialogRelatedLawsuit = true
    },
    onDeleteRelatedLawsuit(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `lawsuit/lawsuit/${this.lawsuitId}/related-lawsuit/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getRelatedLawsuit()
        })
      })
      this.loading = false
    },
    onCloseRelatedLawsuit() {
      this.dialogRelatedLawsuit = false
      this.$nextTick(() => {
        this.editingRelatedLawsuit = {}
      })
      this.loading = false
    },
    async onSaveRelatedLawsuit() {
      if (!this.editingRelatedLawsuit.lawsuit) {
        return
      }
      try {
        this.loading = true
        const data = {}
        data.lawsuit = this.editingRelatedLawsuit.lawsuit.id

        if (this.editingRelatedLawsuit.id) {
          await this.$api({
            method: 'put',
            url: `lawsuit/lawsuit/${this.lawsuitId}/related-lawsuit/${this.editingRelatedLawsuit.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `lawsuit/lawsuit/${this.lawsuitId}/related-lawsuit/`,
            data
          })
        }
        this.editingRelatedLawsuit = {}
        this.onCloseRelatedLawsuit()
        await this.getRelatedLawsuit()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getRelatedRevenue(options = null) {
      if (!this.lawsuitId) {
        return
      }

      this.loading = true
      try {
        let params = defaultTableParams(options)

        const { data } = await this.$api({
          method: 'get',
          url: `lawsuit/lawsuit/${this.lawsuitId}/related-revenue/`,
          params: params,
          hideSuccessAlert: true
        })
        this.relatedRevenues = data.results
        this.relatedRevenuesCount = data.count ?? 0
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditRelatedRevenue(item) {
      this.editingRelatedRevenue = {
        revenue: Object.assign({}, item)
      }
      this.dialogRelatedRevenue = true
    },
    onDeleteRelatedRevenue(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `lawsuit/lawsuit/${this.lawsuitId}/related-revenue/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getRelatedRevenue()
        })
      })
      this.loading = false
    },
    onCloseRelatedRevenue() {
      this.dialogRelatedRevenue = false
      this.$nextTick(() => {
        this.editingRelatedRevenue = {}
      })
      this.loading = false
    },
    async onSaveRelatedRevenue() {
      if (!this.editingRelatedRevenue.revenue) {
        return
      }
      try {
        this.loading = true
        const data = {}
        data.revenue = this.editingRelatedRevenue.revenue.id

        if (this.editingRelatedRevenue.id) {
          await this.$api({
            method: 'put',
            url: `lawsuit/lawsuit/${this.lawsuitId}/related-revenue/${this.editingRelatedRevenue.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `lawsuit/lawsuit/${this.lawsuitId}/related-revenue/`,
            data
          })
        }
        this.editingRelatedRevenue = {}
        this.onCloseRelatedRevenue()
        await this.getRelatedRevenue()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getRelatedExpense(options = null) {
      if (!this.lawsuitId) {
        return
      }

      this.loading = true
      try {
        let params = defaultTableParams(options)

        const { data } = await this.$api({
          method: 'get',
          url: `lawsuit/lawsuit/${this.lawsuitId}/related-expense/`,
          params: params,
          hideSuccessAlert: true
        })
        this.relatedExpenses = data.results
        this.relatedExpensesCount = data.count ?? 0
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    onEditRelatedExpense(item) {
      this.editingRelatedExpense = {
        expense: Object.assign({}, item)
      }
      this.dialogRelatedExpense = true
    },
    onDeleteRelatedExpense(items) {
      this.loading = true
      this.$confirmDelete(items, () => {
        return this.$api({
          method: 'delete',
          url: `lawsuit/lawsuit/${this.lawsuitId}/related-expense/`,
          data: {
            pks: items.map((value) => value.id)
          }
        }).then(() => {
          this.selected = []
          this.getRelatedExpense()
        })
      })
      this.loading = false
    },
    onCloseRelatedExpense() {
      this.dialogRelatedExpense = false
      this.$nextTick(() => {
        this.editingRelatedExpense = {}
      })
      this.loading = false
    },
    async onSaveRelatedExpense() {
      if (!this.editingRelatedExpense.expense) {
        return
      }
      try {
        this.loading = true
        const data = {}
        data.expense = this.editingRelatedExpense.expense.id

        if (this.editingRelatedExpense.id) {
          await this.$api({
            method: 'put',
            url: `lawsuit/lawsuit/${this.lawsuitId}/related-expense/${this.editingRelatedExpense.id}/`,
            data
          })
        } else {
          await this.$api({
            method: 'post',
            url: `lawsuit/lawsuit/${this.lawsuitId}/related-expense/`,
            data
          })
        }
        this.editingRelatedExpense = {}
        this.onCloseRelatedExpense()
        await this.getRelatedExpense()
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
